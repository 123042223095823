import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Checkout from "./pages/checkout/checkout";
import Home from "./pages/home/home";
import ProductPage from "./pages/productPage/productPage";
import { Fragment, useEffect, useState, useContext } from "react";
import Collection from "./pages/collection/collection";
import Header from "./pages/header/header";
import Footer from "./pages/Footer/Footer";
import { AppProvider } from "./AppContext";
import Sidebar from "./Admin/Sidebar/Sidebar";
import Dashboard from "./Admin/Dashboard/Dashboard";
import { AddProduct } from "./Admin/Products/AddProduct";
import { Products } from "./Admin/Products/Products";
import { Orders } from "./Admin/Orders/Orders";
import { Vouchers } from "./Admin/Vouchers/Vouchers";
import { Collections } from "./Admin/Collection/collection";
import { News } from "./Admin/News/News";
import { HomeAdmin } from "./Admin/HomeAdmin/HomeAdmin";
import Subscribers from "./Admin/Subscribers/Subscribers";
import { Login } from "./Admin/Login/Login";
import { AppContext } from "./AppContext";

function App() {
  // const { isAdmin } = useContext(AppContext);

  const handleScrollReset = () => {
    window.scrollTo(0, 0);
  };

  return (
    <AppProvider>
      <BrowserRouter>
        <SomeComponent onMount={handleScrollReset}>
          <MainContent />
        </SomeComponent>
      </BrowserRouter>
    </AppProvider>
  );
}

function SomeComponent({ onMount, children }) {
  const location = useLocation();

  useEffect(() => {
    onMount();
  }, [location]);

  return children;
}

function MainContent() {
  const location = useLocation();
  const loginPage = isLoginPage(location);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if admin flag is present in localStorage
    const adminFlag = localStorage.getItem("isAdmin");
    if (adminFlag) {
      setIsAdmin(true);
    }
  }, []);

  return (
    <>
      {!loginPage && !isAdmin && <Header />}
      {isAdmin && !loginPage && <Sidebar />}
      <div
        id="content"
        className={isAdmin && !loginPage ? "contentwrapper" : ""}
      >
        <div className={isAdmin && !loginPage ? "pageswrapper" : ""}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/product/:productId" element={<ProductPage />} />
            <Route path="/collection/:collectionId" element={<Collection />} />
            <Route path="/login" element={<Login setIsAdmin={setIsAdmin} />} />
            {isAdmin && !loginPage && (
              <Fragment>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/addproduct" element={<AddProduct />} />
                <Route path="/admin/products" element={<Products />} />
                <Route path="/admin/orders" element={<Orders />} />
                <Route path="/admin/vouchers" element={<Vouchers />} />
                <Route path="/admin/collections" element={<Collections />} />
                <Route path="/admin/news" element={<News />} />
                <Route path="/admin/home" element={<HomeAdmin />} />
                <Route path="/admin/subscribers" element={<Subscribers />} />
              </Fragment>
            )}
          </Routes>
        </div>
      </div>
    </>
  );
}

function isLoginPage(location) {
  return location.pathname === "/login";
}

export default App;
