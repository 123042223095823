import React, { useState, useContext, useEffect } from "react";
import logo from "../../assets/images/BlackLogo.png"; // Adjust the path to your logo
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/main.css";
// import '../css/util.css'

export function Login({ setIsAdmin }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    const res = await axios.post("https://api.detacheg.com/api/users/login", {
      email,
      password,
    });
    if (res?.data?.token?.userID === "") {
      message.error("Invalid email or password");
      return;
    }
    if (res?.data?.token !== "") {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("isAdmin", true); // Store isAdmin flag in localStorage
      setIsAdmin(true);
      navigate("/admin/products");
    }
  };

  return (
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <div class="login100-pic js-tilt" data-tilt>
            <img src={logo} alt="IMG" />
          </div>

          <form class="login100-form validate-form">
            <span class="login100-form-title">Member Login</span>

            <div
              class="wrap-input100 validate-input"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <input
                class="input100"
                type="text"
                name="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
            </div>

            <div
              class="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <input
                class="input100"
                type="password"
                name="pass"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>

            <div class="container-login100-form-btn">
              <Button
                onClick={login}
                style={{ backgroundColor: "#1677FF", color: "white" }}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
